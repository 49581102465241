import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import SectionTitle from '../Utils/SectionTitle';
import archimg from '../../Resources/images/Architecture.png'
import Fade from 'react-reveal/Fade';

const Architecture = () => {
    return (
        <Container
        style={{
            height: '100%',
            width: 'auto',
            background: `#1A315C no-repeat center`,
            backgroundSize: 'cover',
        }}
        fluid>

            <SectionTitle title="Architecture" color='#ffffff'/>


            <Container>
                <Row style={{
                    marginTop: '60px',
                    paddingBottom: '60px'

                }}>
                    
                        <Col md={6}>
                        <Fade delay={700}>
                            <p className="lead-text" style={{
                                textAlign:'justify',
                                paddingTop: '50px',
                                color:'#ffffff'
                            
                            }}>
                            A single server contains the brain behind Faciem, this server can be deployed both locally 
                            and on the cloud. Media from multiple locations is sent to the Faciem server to be processed for known identities. 
                            The media can be a live feed from CCTV cameras, a saved video or a single image. Faciem provides the provision of handling
                            multiple types of media inputs. The Faciem server contains a Face database consisting of known faces. Faces in the input media 
                            are processed against this database and a result is generated as a response containing the found identity.
                            </p>
                            </Fade> 
                        </Col>
                        
                    <Col md={6} className="align-items-center" style={{
                        paddingBottom: '50px',
                        marginTop: '10px'
                    }}>
                        <Fade delay={900}>
                            <Image  src={archimg} fluid/>
                        </Fade>
                    </Col>
                  
                </Row>
            </Container>

        </Container>
    );
};

export default Architecture;