import React from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import bgimage from '../../Resources/images/home.png';
import Typical from 'react-typical';


export default function Home() {
    return (

        <Container
            className="home-content"
            style={{
                height: `${window.innerHeight}px`,
                width: '100%',
                background: `url(${bgimage}) no-repeat center`,
                backgroundSize: 'cover',
                overflowX: 'hidden',
                overflowY: 'hidden'
            }}
            fluid>

        

                <Row
                    style={{
                        overflowX: 'hidden',
                        overflowY: 'hidden'
                    }}
                >
                        <Col md={12} sm={6}>
                            <h2 className="main">    
                                 <Typical
                                    steps={['The Only Face Recognition Technology', 3000]}
                                    loop={Infinity}
                                    wrapper="b"
                                 />
                            </h2>
                            <Fade delay={2000}>
                                <h3 className="sub"> You will ever need </h3>
                            </Fade>    
                        </Col>
                </Row>
                <Row
                    className="text-center"
                    style={{
                        paddingTop: '50px',
                        paddingLeft: '30px',
                        paddingBottom: '50px',
                        overflowX: 'hidden'

                    }}
                >
                    <Fade delay={2500}>
                    <a href="mailto:info@bytecorp.io" target="_blank" rel="noopener noreferrer">
                        <Button variant="info"
                            style={{
                                width: '200px',
                                height: '60px',
                                fontSize: '20px'
                            }}>
                            Get in Touch</Button>
                            </a>
                    </Fade>
                </Row>

        </Container>
    )
}
