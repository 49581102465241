import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import logo from '../../Resources/images/faciem-logo-light.png';
import Fade from 'react-reveal';

const Footer = () => {
    return (
        <Container
            style={{
                padding: "25px 10px",
                background: "#1A315C",
                boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
            }}
            className="text-center justify-content-center"
        fluid
        >
            <Row>
                <Col md={6} className="justify-items-left pt-lg-5">
                    <Fade delay={600}>
                    <Image src={logo} alt="logo" fluid
                        style={{
                            width:'15rem',
                            height: 'auto'
                        }}
                    />
                    </Fade>
                </Col>
                <Col md={6} className="justify-items-right pl-5"
                    style={{
                        marginTop:'100px'
                    }}
                >
                    <Fade delay={600}>
                    <a href="https://www.facebook.com/bytecorp.io" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook"
                        style={{
                            fontSize:"3rem",
                            color:"#3b5998",
                            marginRight:"15px"
                        }}
                    />
                    </a>

                    <a href="https://www.instagram.com/bytecorp_io/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"
                           style={{
                            fontSize:"3rem",
                            color:"#3f729b",
                            marginRight:"15px"
                        }}
                    />
                    </a>

                    <a href="https://www.linkedin.com/company/bytecorp/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"
                        style={{
                            fontSize:"3rem",
                            color:"#0e76a8",
                            marginRight:"15px"
                        }}
                    />
                    </a>
                    </Fade>
                </Col>
            </Row>

            <Fade delay={700}>
            <hr style={{
                backgroundColor: "#ffffff"
            }}/>
            </Fade>

            <Row>
                <Col md={12}>
                <Fade delay={900}>
                    <h5 className="text-footer" >FACIEM by  <a href="http://www.bytecorp.io" target="_blank" rel="noopener noreferrer">ByteCorp (2020)</a> All rights reserved.</h5>   
                </Fade>
                </Col>
            </Row>

        </Container>
    );
};

export default Footer;