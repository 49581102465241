import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import  List  from '@material-ui/core/List';
import  ListItem  from '@material-ui/core/ListItem';
import { scroller } from 'react-scroll';
import logo from '../../Resources/images/white.png'
import Fade from 'react-reveal/Fade';


const SideDrawer = (props) => {

    const scrollToElement = (element) =>{
        scroller.scrollTo(element,{
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -75
        })
        props.onClose(false)
    }


    return (
       <Drawer
        anchor="right"
        open={props.open}
        onClose={()=>props.onClose(false)}
       >
           <div className="logo-container">
                <img src={logo} className="logo-drawer" alt="Logo"></img> 
            </div>

            <Fade delay={300}>   
            <hr className="bg-black"/>     
            </Fade>

            <List component="nav">
                <Fade delay={400}>               
                <ListItem button onClick={()=>scrollToElement('home')}>
                    HOME
                </ListItem>
                </Fade>

                <Fade delay={500}>
                <ListItem button onClick={()=>scrollToElement('about')}>
                    ABOUT
                </ListItem>
                </Fade>

                <Fade delay={600}>
                <ListItem button onClick={()=>scrollToElement('architecture')}>
                    ARCHITECTURE
                </ListItem>
                </Fade>

                <Fade delay={700}>
                <ListItem button onClick={()=>scrollToElement('features')}>
                    FEATURES
                </ListItem>
                </Fade>

                <Fade delay={800}>
                <ListItem button onClick={()=>scrollToElement('applications')}>
                    APPLICATIONS
                </ListItem>
                </Fade>
                <Fade delay={800}>
                <ListItem button onClick={()=>scrollToElement('product')}>
                    PRODUCT
                </ListItem>
                </Fade>

            </List>

            <Fade delay={1000}>   
                <hr className="bg-black"/>     
            </Fade>

         
       </Drawer>
    );
};

export default SideDrawer;