import React, { Component } from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import {Image} from 'react-bootstrap';
import headerLogo from '../../Resources/images/faciem-logo-light.png';
import SideDrawer from './SideDrawer';


class Header extends Component {

  state = {
    drawerOpen : false,
    headerShow : false
  }

  componentDidMount(){
    window.addEventListener('scroll',this.handleScroll);
  }

  toggleDrawer = (value) => {
    this.setState({
      drawerOpen: value
    })
  }

  handleScroll = () => {
    if(window.scrollY > 0){
      this.setState({
        headerShow: true
      })
    }else
      {
        this.setState({
          headerShow: false
        })
      }
  }


  render() {
    return (
      <AppBar
        position = "fixed"
        style={{
            transition: '0.5s',
            background:this.state.headerShow ? '1A315C' : '#1A315C',
            boxShadow: this.state.headerShow ? '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)' : 'none',
            padding:'5px'
        }}
      >

        <Toolbar>
          <div className="header-title">
              <Image src={headerLogo} fluid style={{
                  width: '200px',
                  height: 'auto'
              }}/>
          </div>

          <IconButton
                        edge="end"
                        aria-label="menu"
                        onClick={(value)=>this.toggleDrawer(true)}
                        style={{
                            alignItems:'flex-end',
                            position: 'fixed',
                            right: '0',
                            color: 'white',
                            borderRadius: '10px',
                            marginRight: '4px',
                            paddingBottom: '10px',
                            backgroundColor:'none'
                        }}
                    >
                        <MenuIcon className="menu-icon"/>
                    </IconButton>

                    <SideDrawer
                      open = {this.state.drawerOpen}
                      onClose = {(value)=>this.toggleDrawer(value)}
                    />
        </Toolbar>
      </AppBar>
    )
  }
}

export default Header;