import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import SectionTitle from '../Utils/SectionTitle';
import Fade from 'react-reveal/Fade';
import logo from '../../Resources/images/logo.png';

const Applications = () => {
    return (
        <Container
        style={{
            height: '100%',
            width: 'auto',
            background: `#1A315C no-repeat center`,
            backgroundSize: 'cover',
        }}
        fluid>

            <SectionTitle title="Applications" color='#ffffff'/>


            <Container>
                <Row style={{
                    marginTop: '50px',
                    paddingBottom: '60px'

                }}>
                    
                        <Col md={6}>
                            <p className="lead-text-ap">
                                Following are some of the applications where Faciem has proved
                             to be remarkable, whereas the complete potential of the technology is still 
                             being explored:</p>
                            <ul className="application-list">
                                  <Fade delay={600}>
                                          <li>Smart Safe Cities</li>
                                    </Fade>
                            
                                    <Fade delay={800}>
                                             <li>Smart Surveillance</li>
                                     </Fade>
                            
                                    <Fade delay={1000}>
                                            <li>Automated Attendance System</li>
                                    </Fade>
                                    <Fade delay={1200}>
                                            <li>Access Control</li>
                                    </Fade>
                            
                                    <Fade delay={1400}>
                                             <li>Driver Verification Systems</li>                                     
                                    </Fade>
                            
                                    <Fade delay={1600}>
                                          <li>Crowd Management</li>                                  
                                    </Fade>
                            
                                    <Fade delay={1800}>
                                          <li>Intrusion Alert</li>                                  
                                    </Fade>    
                            
                                    <Fade delay={2000}>
                                          <li>Retail Market Solutions</li>                                  
                                    </Fade>                        
                             </ul>
                          
                        </Col>
                        
                    <Col md={6} className="align-items-center" style={{
                        paddingTop: '50px',
                        paddingBottom: '50px'
                    }}>
                        <Fade delay={1000}>
                            <Image  src={logo} fluid/>
                        </Fade>
                    </Col>
                  
                </Row>
            </Container>

        </Container>
    );
};

export default Applications;