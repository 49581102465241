import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import netimg from '../../Resources/images/Element1.1.png'
import SectionTitle from '../Utils/SectionTitle';  

const Features = () => {
    return (
        <Container
        style={{
            height: '100%',
            width: 'auto',
            background: `url(${netimg}) no-repeat center`,
            backgroundSize: 'cover'
        }}
        fluid>

            <SectionTitle title="Features" color="#1A315C"/>

            <Container>
                <Row style={{
                    marginTop: '70px',
                    marginBottom:'50px'
                }}>
                    <Col md={6}>
                        <ul className="feature-list">
                            <Fade delay={600}>
                                <li>Integration to your existing application or can be developed as a different use-case</li>
                            </Fade>
                            <br/>
                            <Fade delay={800}>
                                <li>Uses deep learning techniques to improve matching and detection algorithms</li>
                            </Fade>
                            <br/>
                            <Fade delay={1000}>
                                <li>Inputs can be video or stills, people or objects</li>
                            </Fade>
                        </ul>
                    </Col>
                    <Col md={6}>
                        <ul className="feature-list">
                            <Fade delay={1200}>
                                <li>Fully managed cloud based solution, or can be deployed in-house i.e. locally</li>
                            </Fade>    
                            <br/>
                            <Fade delay={1400}>
                                <li>Choice of technologies dependent upon use case and security requirements</li>
                            </Fade>
                            <br/>
                            <Fade delay={1600}>
                                <li>Fast, Robust and Precise Detection and Verification</li>
                            </Fade>
                        </ul>
                    </Col>
                </Row>
            </Container>

       </Container>
    );
};

export default Features;