import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import aboutimg from '../../Resources/images/Element2.1.png';
import SectionTitle from '../Utils/SectionTitle';
import Fade from 'react-reveal/Fade';

export default function About() {
    return (
        <Container
            style={{
                height: '100%',
                width: 'auto',
                background: `url(${aboutimg}) no-repeat center`,
                backgroundSize: 'cover',

            }}
            fluid>

            <SectionTitle title="About" />

            <Container>
                <Row>
                    <Col md={12} style={{
                        marginTop: '50px',
                        marginBottom:'50px'

                    }}>
                        <Fade delay={800}>
                            <p className="lead-text">“Faciem”, is a Face Recognition technology developed and nurtured in ByteCorp.
                            A technology like Faciem required precision, hard work and months of due-diligence to get to the nature of maturity that it possesses now.
                            Face Recognition is a core AI technology and is being coupled with multiple domains among various industries, having a mass-effect on different
                      use-cases, any Face Recognition Technology has to be accurate, fast, robust and flexible; Faciem possesses each of these qualities.</p>
                        </Fade>
                        <br />
                        <Fade delay={800}>
                            <p className="lead-text">
                                Faciem is an independent Face Recognition technology and does not require any specific external hardware to fulfill its purposes.
                                It can be coupled with any vision system to perform remarkable identification, verification and authentication operations.
                            </p>
                        </Fade>
                    </Col>
                </Row>
            </Container>

        </Container>
    )
}
