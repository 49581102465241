import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

const SectionTitle = (props) => {
    return (
        <Container>
        <Fade delay={600}>    
        <Row className="text-center">
            <Col md={12} style={{
                paddingTop: '50px'
            }}>
                 <h3 className="section-title" style={{color:`${props.color}`}}>{props.title}</h3>
            </Col>
        </Row>
        </Fade>
    </Container>
    );
};

export default SectionTitle;