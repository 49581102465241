import React, { Component } from 'react'
import './Resources/style.css';
import { Element } from 'react-scroll';
import Header from './Components/header_footer/Header';
import Home from './Components/Home';
import About from './Components/About';
import Footer from './Components/header_footer/Footer';
import Architecture from './Components/Architecture';
import Features from './Components/Features';
import Applications from './Components/Applications';
import Products from './Components/Products';

export default class App extends Component {
  render() {
    return (
      <div>
          <Header/>

          <Element name="home">
              <Home/>
          </Element>

          <Element name="about">
              <About/>
          </Element>

          <Element name="architecture">
              <Architecture/>
          </Element>

          <Element name="features">
              <Features/>
          </Element>

          <Element name="applications">
              <Applications/>
          </Element>

          <Element name="product">
              <Products/>
          </Element>

          <Footer/>

      </div>
    )
  }
}
