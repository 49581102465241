import React from 'react';
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import SectionTitle from '../Utils/SectionTitle';
import Fade from 'react-reveal/Fade';
import aboutimg from '../../Resources/images/Element2.1.png';
import logoidem from '../../Resources/images/Idem.png';


const Products = () => {
    return (
        <Container
        style={{
            height: `${window.innerHeight}`,
            width: 'auto',
            background: `url(${aboutimg}) no-repeat center`,
            backgroundSize: 'cover',
        }}
        fluid>

            <SectionTitle title="Product" color='#1A315C'/>


            <Container>
                <Row style={{
                    marginTop: '50px',
                    paddingBottom: '60px'

                }}>
                      <Col md={6} className="align-items-center" style={{
                        paddingTop: '20px',
                        paddingBottom: '50px'
                    }}>
                        <Fade delay={1000}>
                            <Image src={logoidem} fluid/>
                        </Fade>
                    </Col>

                        <Col md={6}>
                        <Fade delay={1200}>
                            <p className="lead-text">
                            An Attendance System for your Organization that eliminates time-constraints and maintenance costs exponentially.
                             Just walk-in and walk-out without having to remember to mark your Attendance each time. </p>
                             </Fade>
                            <br/>
                            <br/>

                            <Fade delay={1200}>
                                    <a href="../../Resources/Idem-deck.pdf"  download>
                                        <Button variant="info"
                                            style={{
                                                width: '200px',
                                                height: '60px',
                                                fontSize: '20px'
                                            }}>
                                            Know More</Button>
                                            </a>
                            </Fade>
                          
                        </Col>
                        
                  
                  
                </Row>
            </Container>

        </Container>
    );
};

export default Products;